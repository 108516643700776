///<reference path="../../../../Scripts/typings/jquery/jquery.d.ts"/>

class Header
{
	static RenameTab(workflowId: string, newName: string)
	{
		const tabHeaderElement = this.GetTabHeaderElementByContentIdId(workflowId);
		const tabCaptionElement = this.GetTabCaptionElement(tabHeaderElement);

		tabCaptionElement.text(newName);
	}

	static GetTabContentElementByContentId(workflowId: string) : JQuery
	{
		const firstContentElement = $(`div.fw_${workflowId}:first`);
		const tabContentElement = firstContentElement.parents("div.ui-widget-content:first");

		return tabContentElement;
	}

	static GetTabHeaderElementForContentElement(tabContentElement: JQuery): JQuery
	{
		const tabId = tabContentElement.prop("id");
		return tabContentElement.parents("div.tabmanager-collapsable:first").find(`ul a[page=${tabId}]`).parent();
	}

	static GetTabHeaderElementByContentIdId(workflowId: string) : JQuery
	{
		const contentElement = this.GetTabContentElementByContentId(workflowId);
		return this.GetTabHeaderElementForContentElement(contentElement);
	}

	static GetTabCaptionElement(tabHeaderElement: JQuery): JQuery
	{
		return tabHeaderElement.find("a div.display");
	}
}

window["Header"] = Header;